import React from "react";
import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <div id="Comming_Soon">
      <div className="coming-soon-section-soon">
        <div className="shine-soon">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <a
                className="navbar-brand firstPara-soon"
                style={{ textTransform: "uppercase" }}
              >
                BluO - Hotels, Homestays, <br /> Serviced Apartments
              </a>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <span className="nav-link">
                      BluO - Hotels, Homestays, Serviced Apartments
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <div className="over-soon">
            <div className="container">
              <div className="row display-soon">
                <div className="col-md-7 text-center one_soon">
                  <h1 className="fw-bold heading-soon">
                    COMING <br /> SOON
                  </h1>
                </div>
                
                <div className="col-md-5 text-start two_soon">
                  <p className="mt-3 paragraph_coming-soon">
                    Coming Soon with a Fresh New Look.
                  </p>
                  <p className="paragraph_coming-soon">
                    Meanwhile, please feel free to connect with us via{" "}
                    <a
                      href="mailto:info@bluo.in"
                      className="text-white fw-bold"
                    >
                      {" "}
                      info@bluo.in{" "}
                    </a>
                    or{" "}
                    <a href="tel:+919582222295" className="text-white fw-bold">
                      +91 9582222295
                    </a>{" "}
                    (WhatsApp as well)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
