import React, { useState, useEffect } from "react";
import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
// import './component.css';
import { Link, useNavigate } from "react-router-dom";
import SearchableSelectComponentClient from "../client_select/Component.js";
import { toast } from "react-toastify";

function Component({ isModalOpen, setIsModalOpen, data, onRefresh }) {
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const [hotelName, setHotelName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [address, setAddress] = useState("");
  const [urlName, setUrlName] = useState("");
  const [domain, setDomain] = useState("");
  const [date, setDate] = useState("");

  const [errors, setErrors] = useState({});
  const [clientList, setClientList] = useState([]);
  const [current_id, setCurrent_id] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [accountType, setAccountType] = useState(true);

  const [searchValue, setSearchValue] = useState("All Clients");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState("");

  let clientId = data;

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };
  const handleHotelNameChange = (event) => {
    setHotelName(event.target.value);
  };
  const handlecompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };
  const handlegstNumberChange = (event) => {
    setGstNumber(event.target.value);
  };
  const handleaddressChange = (event) => {
    setAddress(event.target.value);
  };
  const handleURLChange = (event) => {
    setUrlName(event.target.value);
  };
  const handleDomainChange = (event) => {
    setDomain(event.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!hotelName.trim()) {
      errors.hotelName = "Hotel Name is required";
    }
    if (!clientName.trim()) {
      errors.clientName = "Client is required";
    }
    if (!companyName.trim()) {
      errors.companyName = "Company Name is required";
    }
    // if (!gstNumber.trim()) { errors.gstNumber = 'GST Number is required'; }
    if (!address.trim()) {
      errors.address = "Address is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isModalOpen && clientId) {
      fetchData(clientId);
      fetchClientData();
    }
  }, [isModalOpen, clientId]);

  const fetchClientData = async () => {
    try {
      const response = await fetch(url + adminpoints.client_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
      });
      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }
      const data = await response.json();
      setClientList(data.Data);
      console.log(clientList);
    } catch (error) {
      console.error("Error fetching client data:", error.message);
    }
  };

  const fetchData = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(url + adminpoints.hotel_view, {
        method: "POST",
        headers: adminHeader,
        credentials: "include",
        body: JSON.stringify({ filters: { _id: id } }),
      });

      if (!response.ok) {
        navigate(`${admin_prefix}login`);
      }

      const data = await response.json();
      if (data.Status && data.Data.length > 0) {
        let response_data = data.Data[0];
        if (response_data.client_id == null) {
          setClientName("");
          toast.warning("Client name is blank");
        } else {
          setClientName(response_data.client_id._id);
          setSearchValue(response_data.client_id.name);
          setShowDropdown(response_data.client_id.name);
        }
        setCurrent_id(response_data._id);
        setHotelName(response_data.hotel_name || "");
        setUrlName(response_data.urlName || "");
        setDomain(response_data.domain || "");
        setGstNumber(response_data.billing_detail.gstn || "");
        setAddress(response_data.billing_detail.billing_address || "");
        setCompanyName(response_data.billing_detail.legal_company || "");
        setAccountType(response_data.account_type);
        setDate(response_data.createdAt || "");

        // } else {
        //   setClientName('');
        //   setCurrent_id('');
        //   setHotelName('');
        //   setGstNumber('');
        //   setAddress('');
        //   setCompanyName('');
        //   setDate('');
      }
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
      navigate(`${admin_prefix}login`);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAccountType = (event) => {
    setAccountType(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (validateForm()) {
      try {
        const response = await fetch(url + adminpoints.hotel_edit, {
          method: "POST",
          headers: adminHeader,
          credentials: "include",
          body: JSON.stringify({
            filters: { _id: current_id },
            data: {
              client_id: clientName,
              hotel_name: hotelName,
              account_type: accountType,
              urlName: urlName,
              domain: domain,
              billing_detail: {
                gstn: gstNumber,
                billing_address: address,
                legal_company: companyName,
              },
            },
          }),
        });

        if (!response.ok) {
          navigate(`${admin_prefix}login`);
        }

        const responseData = await response.json();

        if (responseData.Status) {
          toast.success(responseData.Message);
          setClientName("");
          setAddress("");
          setGstNumber("");
          setHotelName("");
          setCompanyName("");
          setIsModalOpen(false);
          onRefresh();
        } else {
          toast.error(responseData.Message);
        }
      } catch (error) {
        toast.error(error);
        navigate(`${admin_prefix}login`);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.error("Please fill out all the required fields correctly.");
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
          {/* <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"> */}
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title' id='exampleModalLabel'>
                  Edit Hotel
                </h5>
                <button type='button' className='btn-close' onClick={handleCloseModal}></button>
              </div>

              <form onSubmit={handleSubmit}>
                {loading ? (
                  <div className='modal-body text-center'>
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <label htmlFor='clientName' className='col-form-label'>
                          Client Name:
                        </label>
                        <SearchableSelectComponentClient
                          setClientName={setClientName}
                          searchValueProps={{ searchValue, setSearchValue }}
                          suggestionsProps={{ suggestions, setSuggestions }}
                          showDropdownProps={{ showDropdown, setShowDropdown }}
                          form={false}
                        />
                        {/* <select id="clientName" className={`form-select ${errors.clientName ? 'is-invalid' : ''}`} value={clientName} onChange={handleClientNameChange}>
                        <option value="">Select Client</option>
                        {clientList.map((client) => (
                          <option key={client.id} value={client._id}>{client.name} ({client.phone})</option>
                        ))}
                      </select> */}
                        {errors.clientName && <div className='invalid-feedback'>{errors.clientName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='hotelName' className='col-form-label'>
                          Hotel Name / Brand Name
                        </label>
                        <input type='text' className={`form-control ${errors.hotelName ? "is-invalid" : ""}`} id='hotelName' value={hotelName} onChange={handleHotelNameChange} />
                        {errors.hotelName && <div className='invalid-feedback'>{errors.hotelName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='companyName' className='col-form-label'>
                          Company Name:
                        </label>
                        <input
                          type='text'
                          className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
                          id='companyName'
                          name='companyName'
                          value={companyName}
                          onChange={handlecompanyNameChange}
                        />
                        {errors.companyName && <div className='invalid-feedback'>{errors.companyName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='gstNumber' className='col-form-label'>
                          GST Number:
                        </label>
                        <input type='text' className={`form-control`} id='gstNumber' value={gstNumber} name='gstNumber' onChange={handlegstNumberChange} />
                        {/* {errors.gstNumber && <div className="invalid-feedback">{errors.gstNumber}</div>} */}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='accountType' className='col-form-label'>
                          Account Type:
                        </label>
                        <select id='accountType' className={`form-select`} value={accountType} onChange={handleAccountType}>
                          <option value='true'>Client</option>
                          <option value='false'>Demo</option>
                        </select>
                      </div>
                      <div className='col-md-6'>
                        <label htmlFor='urlName' className='col-form-label'>
                          URL Name:
                        </label>
                        <input type='text' className={`form-control ${errors.urlName ? "is-invalid" : ""}`} id='urlName' name='urlName' value={urlName} onChange={handleURLChange} />
                        {errors.urlName && <div className='invalid-feedback'>{errors.urlName}</div>}
                      </div>

                      <div className='col-md-6'>
                        <label htmlFor='domain' className='col-form-label'>
                          Domain:
                        </label>
                        <input type='text' className={`form-control ${errors.domain ? "is-invalid" : ""}`} id='domain' name='domain' value={domain} onChange={handleDomainChange} />
                        {errors.domain && <div className='invalid-feedback'>{errors.domain}</div>}
                      </div>

                      <div className='col-md-12'>
                        <label htmlFor='address' className='col-form-label'>
                          Address:
                        </label>
                        <input type='text' className={`form-control ${errors.address ? "is-invalid" : ""}`} id='address' name='address' value={address} onChange={handleaddressChange} />
                        {errors.address && <div className='invalid-feedback'>{errors.address}</div>}
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div> */}
                <div className='modal-footer d-flex justify-content-between align-items-center'>
                  <p className='float-start'>CreatedAt : {new Date(date).toLocaleDateString()}</p>
                  <div className='d-flex gap-2'>
                    <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                      Close
                    </button>
                    <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                      {isSubmitting ? "Loading..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Component;

// import React, { useState, useEffect } from "react";
// import { adminpoints, url, adminHeader, admin_prefix } from "../../../../../lib/lib.js";
// // import './component.css';
// import { Link, useNavigate } from "react-router-dom";
// import SearchableSelectComponentClient from "../client_select/Component.js";
// import { toast } from "react-toastify";

// function Component({ isModalOpen, setIsModalOpen, data, onRefresh }) {
//   const navigate = useNavigate();
//   const [clientName, setClientName] = useState("");
//   const [hotelName, setHotelName] = useState("");
//   const [companyName, setCompanyName] = useState("");
//   const [gstNumber, setGstNumber] = useState("");
//   const [address, setAddress] = useState("");
//   const [date, setDate] = useState("");

//   const [errors, setErrors] = useState({});
//   const [clientList, setClientList] = useState([]);
//   const [current_id, setCurrent_id] = useState("");
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const [accountType, setAccountType] = useState(true);

//   const [searchValue, setSearchValue] = useState("All Clients");
//   const [suggestions, setSuggestions] = useState([]);
//   const [showDropdown, setShowDropdown] = useState("");

//   let clientId = data;

//   const handleClientNameChange = (event) => {
//     setClientName(event.target.value);
//   };
//   const handleHotelNameChange = (event) => {
//     setHotelName(event.target.value);
//   };
//   const handlecompanyNameChange = (event) => {
//     setCompanyName(event.target.value);
//   };
//   const handlegstNumberChange = (event) => {
//     setGstNumber(event.target.value);
//   };
//   const handleaddressChange = (event) => {
//     setAddress(event.target.value);
//   };

//   const validateForm = () => {
//     const errors = {};
//     if (!hotelName.trim()) {
//       errors.hotelName = "Hotel Name is required";
//     }
//     if (!clientName.trim()) {
//       errors.clientName = "Client is required";
//     }
//     if (!companyName.trim()) {
//       errors.companyName = "Company Name is required";
//     }
//     // if (!gstNumber.trim()) { errors.gstNumber = 'GST Number is required'; }
//     if (!address.trim()) {
//       errors.address = "Address is required";
//     }
//     setErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     if (isModalOpen && clientId) {
//       fetchData(clientId);
//       fetchClientData();
//     }
//   }, [isModalOpen, clientId]);

//   const fetchClientData = async () => {
//     try {
//       const response = await fetch(url + adminpoints.client_view, {
//         method: "POST",
//         headers: adminHeader,
//         credentials: "include",
//       });
//       if (!response.ok) {
//         navigate(`${admin_prefix}login`);
//       }
//       const data = await response.json();
//       setClientList(data.Data);
//       console.log(clientList);
//     } catch (error) {
//       console.error("Error fetching client data:", error.message);
//     }
//   };

//   const fetchData = async (id) => {
//     setLoading(true);
//     try {
//       const response = await fetch(url + adminpoints.hotel_view, {
//         method: "POST",
//         headers: adminHeader,
//         credentials: "include",
//         body: JSON.stringify({ filters: { _id: id } }),
//       });

//       if (!response.ok) {
//         navigate(`${admin_prefix}login`);
//       }

//       const data = await response.json();
//       if (data.Status && data.Data.length > 0) {
//         let response_data = data.Data[0];
//         if (response_data.client_id == null) {
//           setClientName("");
//           toast.warning("Client name is blank");
//         } else {
//           setClientName(response_data.client_id._id);
//           setSearchValue(response_data.client_id.name);
//           setShowDropdown(response_data.client_id.name);
//         }
//         setCurrent_id(response_data._id);
//         setHotelName(response_data.hotel_name || "");
//         setGstNumber(response_data.billing_detail.gstn || "");
//         setAddress(response_data.billing_detail.billing_address || "");
//         setCompanyName(response_data.billing_detail.legal_company || "");
//         setDate(response_data.createdAt || "");

//         // } else {
//         //   setClientName('');
//         //   setCurrent_id('');
//         //   setHotelName('');
//         //   setGstNumber('');
//         //   setAddress('');
//         //   setCompanyName('');
//         //   setDate('');
//       }
//       setLoading(false);
//     } catch (error) {
//       toast.error(error);
//       setLoading(false);
//       navigate(`${admin_prefix}login`);
//     }
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (isSubmitting) return;
//     setIsSubmitting(true);
//     if (validateForm()) {
//       try {
//         const response = await fetch(url + adminpoints.hotel_edit, {
//           method: "POST",
//           headers: adminHeader,
//           credentials: "include",
//           body: JSON.stringify({
//             filters: { _id: current_id },
//             data: {
//               client_id: clientName,
//               hotel_name: hotelName,
//               billing_detail: {
//                 gstn: gstNumber,
//                 billing_address: address,
//                 legal_company: companyName,
//               },
//             },
//           }),
//         });

//         if (!response.ok) {
//           navigate(`${admin_prefix}login`);
//         }

//         const responseData = await response.json();

//         if (responseData.Status) {
//           toast.success(responseData.Message);
//           setClientName("");
//           setAddress("");
//           setGstNumber("");
//           setHotelName("");
//           setCompanyName("");
//           setIsModalOpen(false);
//           onRefresh();
//         } else {
//           toast.error(responseData.Message);
//         }
//       } catch (error) {
//         toast.error(error);
//         navigate(`${admin_prefix}login`);
//       } finally {
//         setIsSubmitting(false);
//       }
//     } else {
//       toast.error("Please fill out all the required fields correctly.");
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <>
//       {isModalOpen && (
//         <div className='modal fade show' id='exampleModal' tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' style={{ display: "block" }}>
//           {/* <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable"> */}
//           <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg'>
//             <div className='modal-content'>
//               <div className='modal-header'>
//                 <h5 className='modal-title' id='exampleModalLabel'>
//                   Edit Hotel
//                 </h5>
//                 <button type='button' className='btn-close' onClick={handleCloseModal}></button>
//               </div>

//               <form onSubmit={handleSubmit}>
//                 {loading ? (
//                   <div className='modal-body text-center'>
//                     <div className='spinner-border' role='status'>
//                       <span className='visually-hidden'>Loading...</span>
//                     </div>
//                   </div>
//                 ) : (
//                   <div className='modal-body'>
//                     <div className='row'>
//                       <div className='col-md-6'>
//                         <label htmlFor='clientName' className='col-form-label'>
//                           Client Name:
//                         </label>
//                         <SearchableSelectComponentClient
//                           setClientName={setClientName}
//                           searchValueProps={{ searchValue, setSearchValue }}
//                           suggestionsProps={{ suggestions, setSuggestions }}
//                           showDropdownProps={{ showDropdown, setShowDropdown }}
//                           form={false}
//                         />
//                         {/* <select id="clientName" className={`form-select ${errors.clientName ? 'is-invalid' : ''}`} value={clientName} onChange={handleClientNameChange}>
//                         <option value="">Select Client</option>
//                         {clientList.map((client) => (
//                           <option key={client.id} value={client._id}>{client.name} ({client.phone})</option>
//                         ))}
//                       </select> */}
//                         {errors.clientName && <div className='invalid-feedback'>{errors.clientName}</div>}
//                       </div>

//                       <div className='col-md-6'>
//                         <label htmlFor='hotelName' className='col-form-label'>
//                           Hotel Name / Brand Name
//                         </label>
//                         <input type='text' className={`form-control ${errors.hotelName ? "is-invalid" : ""}`} id='hotelName' value={hotelName} onChange={handleHotelNameChange} />
//                         {errors.hotelName && <div className='invalid-feedback'>{errors.hotelName}</div>}
//                       </div>

//                       <div className='col-md-6'>
//                         <label htmlFor='companyName' className='col-form-label'>
//                           Company Name:
//                         </label>
//                         <input
//                           type='text'
//                           className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
//                           id='companyName'
//                           name='companyName'
//                           value={companyName}
//                           onChange={handlecompanyNameChange}
//                         />
//                         {errors.companyName && <div className='invalid-feedback'>{errors.companyName}</div>}
//                       </div>

//                       <div className='col-md-6'>
//                         <label htmlFor='gstNumber' className='col-form-label'>
//                           GST Number:
//                         </label>
//                         <input type='text' className={`form-control`} id='gstNumber' value={gstNumber} name='gstNumber' onChange={handlegstNumberChange} />
//                         {/* {errors.gstNumber && <div className="invalid-feedback">{errors.gstNumber}</div>} */}
//                       </div>

//                       <div className='col-md-12'>
//                         <label htmlFor='address' className='col-form-label'>
//                           Address:
//                         </label>
//                         <input type='text' className={`form-control ${errors.address ? "is-invalid" : ""}`} id='address' name='address' value={address} onChange={handleaddressChange} />
//                         {errors.address && <div className='invalid-feedback'>{errors.address}</div>}
//                       </div>
//                     </div>
//                   </div>
//                 )}
//                 {/* <div className="modal-footer">
//                   <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
//                   <button type="submit" className="btn btn-primary">Submit</button>
//                 </div> */}
//                 <div className='modal-footer d-flex justify-content-between align-items-center'>
//                   <p className='float-start'>CreatedAt : {new Date(date).toLocaleDateString()}</p>
//                   <div className='d-flex gap-2'>
//                     <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
//                       Close
//                     </button>
//                     <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
//                       {isSubmitting ? "Loading..." : "Update"}
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default Component;
