import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthUserContext } from "./AuthUserContext.js"; // Adjust path as needed

const useHotelId = () => {
  const { hotelId: paramHotelId } = useParams();
  const { hotelUrl } = useContext(AuthUserContext);
  const [hotelId, setHotelId] = useState(paramHotelId || hotelUrl);

  useEffect(() => {
    if (!paramHotelId?.trim() && hotelUrl) {
      setHotelId(hotelUrl);
    } else if (paramHotelId) {
      setHotelId(paramHotelId);
    }
  }, [paramHotelId, hotelUrl]);

  return hotelId;
};

export default useHotelId;
