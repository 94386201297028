import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

const Component = () => {
  return (
    <>
        <h1>Hi</h1>
      {/* <Routes>
        <Route path="/" element={<ComingSoonScreen />} />
      </Routes> */}
    </>
  );
};

export default Component;
