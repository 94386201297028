import React, { useState, useEffect, useContext, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import {
  url,
  clientpoints,
  whoAmIHeaders,
  getDecryptedDataFromLocalStorage,
  client_prefix,
} from "../../../lib/lib.js";
import { AuthUserContext } from "../../../lib/AuthUserContext.js";
// import DashboardCard from '../components/dashboard/Component.js';
import { LoadingMutatingDots } from "../components/ui/Loadings";
import DashboardCard from "../components/Dashboard/Component.js";

const Component = () => {
  const navigate = useNavigate();
  const { whoAmI, setWhoAmI } = useContext(AuthUserContext);
  const { typeTeamRoleObject } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  let getLocalStorageData;

  useEffect(() => {
    if (!whoAmI) {
      getLocalStorageData = getDecryptedDataFromLocalStorage("type");
      setWhoAmI(getLocalStorageData);
      pageHeaders = whoAmIHeaders(getLocalStorageData);
    } else {
      pageHeaders = whoAmIHeaders(whoAmI);
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState("");
  const { hotelId } = useParams();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const requestBody = { urlName: hotelId };

      const response = await fetch(url + clientpoints.dashboard, {
        method: "POST",
        headers: pageHeaders,
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      // if (!response.ok) {
      //   navigate(`${client_prefix}`);
      // }

      const data = await response.json();
      setRecord(data.Data);
      console.log("dataaaaa", data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (pageHeaders) {
      fetchData();
    }
  }, []);

  const handleRefresh = () => {
    fetchData();
  };

  return (
    <>
      <ToastContainer />
      <div className='dashbord-option'>
        <div className='inner-padding'>
          <div className='featured-flx mb-0'>
            <div className='featured-flx1'>
              <p className='main_heading1'>Dashboard</p>
            </div>

            <div className='featured-flx1'>
              <button
                className='circle-btn-add icon'
                type='button'
                onClick={handleRefresh}
              >
                {isLoading ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  ></span>
                ) : (
                  <i className='ri-refresh-line'></i>
                )}
              </button>
            </div>
          </div>

          <br />
          {isLoading ? (
            <LoadingMutatingDots />
          ) : (
            <div className='row'>
              {whoAmI === "team" ? (
                <>
                  {typeTeamRoleObject?.booking?.view && (
                    <>
                    <DashboardCard
                      getTitle='Total Booking'
                      getValue={record.todayBooking}
                      page='booking'
                    />
                    <DashboardCard
                      getTitle='Yesterday Booking'
                      getValue={record.yesterdayBooking}
                      page='booking'
                    />
                    <DashboardCard
                      getTitle='This Week Booking'
                      getValue={record.weekBooking}
                      page='booking'
                    />
                    <DashboardCard
                      getTitle='This Month Booking'
                      getValue={record.monthBooking}
                      page='booking'
                    />
                    </>
                  )}
                  {typeTeamRoleObject?.Property?.view && (
                    <DashboardCard
                      getTitle='Total Property'
                      getValue={record.totalProperty}
                      page='hotel'
                    />
                  )}
                  {typeTeamRoleObject?.user?.view && (
                    <DashboardCard
                      getTitle='Total Customer'
                      getValue={record.totalCustomer}
                      page='all-clients'
                    />
                  )}
                  {typeTeamRoleObject?.enquiry?.view && (   
                    <DashboardCard
                      getTitle='Total Enquiries'
                      getValue={record.totalEnquiries}
                      page='all-enquiries'
                    />
                  )}
                  {typeTeamRoleObject?.post?.view && (
                    <DashboardCard
                      getTitle='Total Community'
                      getValue={record.in_active_space}
                      page='community/posts'
                      filters='false'
                    />
                  )}
                  {typeTeamRoleObject?.ticket?.view && (
                    <DashboardCard
                      getTitle='Total Request'
                      getValue={record.totalRequest}
                      page='staystation-support'
                    />
                  )}
                  {typeTeamRoleObject?.subscription?.view && (
                    <>
                    <DashboardCard
                      getTitle='Today Revenue'
                      getValue={record.todayRevenue}
                      page='subscription'
                    />
                    <DashboardCard
                      getTitle='Yesterday Revenue'
                      getValue={record.yesterdayRevenue}
                      page='subscription'
                    />
                    <DashboardCard
                      getTitle='This Week Revenue'
                      getValue={record.weekRevenue}
                      page='subscription'
                    />
                    <DashboardCard
                      getTitle='This Month Revenue'
                      getValue={record.monthRevenue}
                      page='subscription'
                    />
                    </>
                  )}
                </>
              ) : (
                <>
                  <DashboardCard
                    getTitle='Total Booking'
                    getValue={record.todayBooking}
                    page='booking'
                  />
                  <DashboardCard
                    getTitle='Yesterday Booking'
                    getValue={record.yesterdayBooking}
                    page='booking'
                  />
                  <DashboardCard
                    getTitle='This Week Booking'
                    getValue={record.weekBooking}
                    page='booking'
                  />
                  <DashboardCard
                    getTitle='This Month Booking'
                    getValue={record.monthBooking}
                    page='booking'
                  />

                  <DashboardCard
                    getTitle='Today Revenue'
                    getValue={record.todayRevenue}
                    page='subscription'
                  />
                  <DashboardCard
                    getTitle='Yesterday Revenue'
                    getValue={record.yesterdayRevenue}
                    page='subscription'
                  />
                  <DashboardCard
                    getTitle='This Week Revenue'
                    getValue={record.weekRevenue}
                    page='subscription'
                  />
                  <DashboardCard
                    getTitle='This Month Revenue'
                    getValue={record.monthRevenue}
                    page='subscription'
                  />

                  <DashboardCard
                      getTitle='Total Property'
                      getValue={record.totalProperty}
                      page='hotel'
                  />
                  <DashboardCard
                    getTitle='Total Customer'
                    getValue={record.totalCustomer}
                    page='all-clients'
                  />
                  <DashboardCard
                    getTitle='Total Enquiries'
                    getValue={record.totalEnquiries}
                    page='all-enquiries'
                  />
                  <DashboardCard
                    getTitle='Total Request'
                    getValue={record.totalRequest}
                    page='staystation-support'
                  />
                
                {/* 
                  <DashboardCard
                    getTitle='Total Team'
                    getValue={record.total_hotel}
                    page='hotel'
                  />
                  <DashboardCard
                    getTitle='Total Visitor'
                    getValue={record.active_hotel}
                    page='hotel'
                    filters='true'
                  />
                  <DashboardCard
                    getTitle='Total Enquiries'
                    getValue={record.in_active_hotel}
                    page='hotel'
                    filters='false'
                  />
                  <DashboardCard
                    getTitle='Total Community'
                    getValue={record.in_active_hotel}
                    page='hotel'
                    filters='false'
                  />
                  <DashboardCard
                    getTitle='Open Ticket'
                    getValue={record.client_ticket}
                    page='support'
                  />
                  <DashboardCard
                    getTitle='Total Revenue'
                    getValue={record.revenue}
                    page='subscription'
                  />
                */}
                  {/* <DashboardCard getTitle="Total Company" getValue={record.company} /> */}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Component;
