import { React, useState, useEffect, lazy } from "react";
import ReactDOM from "react-dom/client";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getDecryptedDataFromLocalStorage, url, domain_name } from "./lib/lib.js";
import { AuthUserContext } from "./lib/AuthUserContext.js";
import {Helmet} from 'react-helmet'

// Start Website Route
import Home from "./website/screens/Home.js";
import Privacy from "./website/screens/Privacy.js";
import Terms from "./website/screens/Terms.js";
import NotFound from "./website/screens/NotFound.js";
import HotelsRoutes from "./panels/hotels/hotels.js";

import AdminRoute from "./panels/admin/admin.js";

import TeamLogin from "./website/screens/TeamLogin.js";

// import DashboardTemp from "./panels/nav/screens/Dashboard.js";
// import DynamicWebisteRoute from "./dynamic_webiste/screens/Home.js";
import DynamicWebisteRoute from "./theme/common/coming_soon/coming_soon.js";
// End Website Route
// import HotelsHome from "./panels/hotels/screens/Home.js";
import FeedbackFormForHotel from "./website/screens/FeedbackFormForHotel.js";
import WebsiteForHotel from "./theme/exclusive/bluokolkata/bluokolkata.js";
import ClientRoutes from "./panels/client/client.js";
// import PrivateRoutes from "./utils/PrivateRoute.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

function App() {
  const [whoAmI, setWhoAmI] = useState(""); // client, team, member as a type
  const [typeTeamRoleObject, setTypeTeamRoleObject] = useState({}); //  Team Role Object
  const [hotelUrl, setHotelUrl] = useState("");

  useEffect(() => {
    const getLocalStorageData = getDecryptedDataFromLocalStorage("type");
    if (whoAmI === null && !getLocalStorageData) {
      window.location.reload(); // Refresh the page if both whoAmI and local storage data are null
    } else if (getLocalStorageData) {
      setWhoAmI(getLocalStorageData);
    }
  }, []);

  // Dynamic Route Rendering
  
  console.log('domain_name - ', domain_name);

  let domain_flag = true;

  if(domain_name == 'staystation.encrobytes.com' || domain_name == "localhost:3000")
  {
    domain_flag = false;
  }

  if(domain_flag){
  // if(true){

    // fetch(url + "/hotel/views", {
    //   method: "POST",
    //   body: JSON.stringify({ domain: domain_name }),
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((response) => {
    //   return response.json();
    // })
    // .then((data) => {
    //   console.log('oko - ',data);
    //   setHotelUrl(data.Data);
    //   // let Main = lazy(() => import("./panels/nav/screens/Dashboard.js"));
    // });

    const fetchData = async () => {
      try {
        const response = await fetch(url + "/hotel/views", {
          method: "POST",
          body: JSON.stringify({ domain: domain_name }),
          // body: JSON.stringify({ domain: 'test.codenap.in' }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        const data = await response.json();
        console.log('oko - ', data);
        setHotelUrl(data.Data);
        // let Main = lazy(() => import("./panels/nav/screens/Dashboard.js"));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return (
      <>
        <AuthUserContext.Provider value={{ whoAmI, setWhoAmI, typeTeamRoleObject, setTypeTeamRoleObject, hotelUrl, setHotelUrl }}>
          <Router>
            <Routes>
              <Route path='/' element={
                <>
                  <Helmet>
                    <title>BluO - Hotels, Homestays, Serviced Apartments</title>
                    <link rel="canonical" href="https://bluo.in/" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="BluO - Hotels, Homestays, Serviced Apartments" />
                    <meta property="og:description" content="Coming Soon Coming Soon with a Fresh New Look. Meanwhile, please feel free to connect with us via info@bluo.in or +919582222295 (WhatsApp as well)" />
                    <meta property="og:url" content="https://bluo.in/" />
                    <meta property="og:site_name" content="BluO - Hotels, Homestays, Serviced Apartments" />
                    <meta property="article:modified_time" content="2025-01-20T07:08:11+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                  </Helmet>
                  <DynamicWebisteRoute />
                </>
                } />
              <Route path='/feedback/:propertyName' element={
                <>
                  <Helmet>
                    <title>Guest Feedback</title>
                    <meta name="description" content="Guest Feedback" />
                    <meta property="og:title" content="Guest Feedback" />
                    <meta property="og:description" content="Guest Feedback" />
                  </Helmet>
                  <FeedbackFormForHotel/>
                  </>
                } />
              <Route path='/website/:websiteUrl' element={<WebsiteForHotel/>} />
              <Route path='/admin/login' element={<TeamLogin />} />
              <Route path='/admin/*' element={<HotelsRoutes />} />
              {/* <Route exact path='/' element={<Main />} /> */}
              <Route path="*" element={<NotFound />}/>
            </Routes>
          </Router>
        </AuthUserContext.Provider>
      </>
    );
    
  }
  else{
    
    return (
      <>
        <AuthUserContext.Provider value={{ whoAmI, setWhoAmI, typeTeamRoleObject, setTypeTeamRoleObject }}>
          <Router>
            <Routes>
              <Route path='Br!Y7o&p45fM/portal/*' element={<AdminRoute />} />#
              
              <Route path='/*' element={
                <>
                <Helmet>
                  <title>StayStation - Hotel Management System</title>
                  <meta name="description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                  <meta name="keywords" content="Hotel Management System, Software Development, Website Development, Mobile App Development, StayStation, Stay Station" />
                  <meta property="og:title" content="StayStation" />
                  <meta property="og:description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                  <meta property="og:image" content="https://staystation.encrobytes.com/favicon.ico" />
                  <meta itemprop="url" content="https://staystation.encrobytes.com/" />
                  <meta property="og:type" content="website" />
                </Helmet>
                <ClientRoutes />
              </>
              }/>

              <Route path='/hotel/:hotelId' element={<DynamicWebisteRoute />} />
              <Route path='/hotel/:hotelId/feedback/:propertyName' element={<FeedbackFormForHotel/>} />
              <Route path='/hotel/:hotelId/admin/login' element={<TeamLogin />} />
              <Route path='/hotel/:hotelId/admin/*' element={<HotelsRoutes />} />
              <Route exact path='/' element={
                <>
                <Helmet>
                  <title>StayStation - Hotel Management System</title>
                  <meta name="description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                  <meta name="keywords" content="Hotel Management System, Software Development, Website Development, Mobile App Development, StayStation, Stay Station" />
                  <meta property="og:title" content="StayStation" />
                  <meta property="og:description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                  <meta property="og:image" content="https://staystation.encrobytes.com/favicon.ico" />
                  <meta itemprop="url" content="https://staystation.encrobytes.com/" />
                  <meta property="og:type" content="website" />
                </Helmet>
                <Home />
              </>
              } />
              <Route exact path='/privacy' element={
                <>
                  <Helmet>
                    <title>Privacy Policy - StayStation</title>
                    <meta name="description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                    <meta name="keywords" content="Hotel Management System, Software Development, Website Development, Mobile App Development, StayStation, Stay Station" />
                    <meta property="og:title" content="StayStation" />
                    <meta property="og:description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                    <meta property="og:image" content="https://staystation.encrobytes.com/favicon.ico" />
                    <meta itemprop="url" content="https://staystation.encrobytes.com/" />
                    <meta property="og:type" content="website" />
                  </Helmet>
                  <Privacy />
                </>
              } />
              <Route exact path='/terms' element={
                <>
                  <Helmet>
                    <title>Terms & Conditions - StayStation</title>
                    <meta name="description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                    <meta name="keywords" content="Hotel Management System, Software Development, Website Development, Mobile App Development, StayStation, Stay Station" />
                    <meta property="og:title" content="StayStation" />
                    <meta property="og:description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                    <meta property="og:image" content="https://staystation.encrobytes.com/favicon.ico" />
                    <meta itemprop="url" content="https://staystation.encrobytes.com/" />
                    <meta property="og:type" content="website" />
                  </Helmet>
                  <Terms />
                </>
              } />
              <Route path="*" element={
                <>
                  <Helmet>
                    <title>Page Not Found</title>
                    <meta name="description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                    <meta name="keywords" content="Hotel Management System, Software Development, Website Development, Mobile App Development, StayStation, Stay Station" />
                    <meta property="og:title" content="StayStation" />
                    <meta property="og:description" content="StayStation is a comprehensive hotel space management system that allows you to efficiently manage your hotel in one place." />
                    <meta property="og:image" content="https://staystation.encrobytes.com/favicon.ico" />
                    <meta itemprop="url" content="https://staystation.encrobytes.com/" />
                    <meta property="og:type" content="website" />
                  </Helmet>
                  <NotFound />
                </>
              }/>
            </Routes>
          </Router>
        </AuthUserContext.Provider>
      </>
    );
  }
    

  // Dynamic Route Rendering

}

root.render(<App />);
