import { Link } from "react-router-dom";
import "./component.css";

const Component = () => {
  return (
    <>
      <div className="hotel-my-top" id="pricing">
        <div className="container">
          <center><p class="hotel-stay-feature">Pricing</p>
          {/* <hr class="hotel-stay-line" /> */}
          <hr className='pricing-line1'></hr>
          </center>
          <div className="row hotel-feature-top02">

            <div className="col-lg-4">
              <div className="hotel-feature-padd2">
                {/* <p className="hotel-stay-txt2">Hotel Management software</p> */}
                {/* <p className="hotel-stay-descri">Order tracking, invoicing and order reports.</p> */}
                <div className="hotel-desk-top-2">
                  {/* <p className="hotel-feature1-txt2">Discover the perfect blend of luxury and affordability with our unbeatable hotel booking prices – your gateway to an unforgettable stay!</p> */}
                  <div className="monthcard">
                  <p class="monthprice-txt2">Monthly Billing Plan</p>
                  <p className="hotel-price">₹60 </p>
                  <p class="price-txt2">Per Room / Month</p>
                  </div>
                  <div className="monthcard">
                  <p class="monthprice-txt2">Yearly Billing Plan</p>
                  <p className="hotel-price">₹50 </p>
                  <p class="price-txt2">Per Room / Month</p>
                  </div>
                  </div>
              </div>
            </div>
            <div className="col-lg-8">
              
              <div className="box1">
                <img src="img/price.png" className="img-fluid pricee-img2" alt="Resources" />
                {/* <img src="img/price.png" className="img-fluid pricee-img20" alt="Resources" /> */}
                <p class="hotel-feature1-txt2">Enjoy powerful features, seamless integration, and exceptional support, all at a price that works for you, with flexible plans designed to scale with your business.</p>
                <div className="row">
                  <div className="col-md-6">
                  <div class="pricing-flx">
                  <div class="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div class="pricing-flx">
                    <p class="price-txt3">Unlimited Multiple Locations</p>
                  </div>
                </div>
                  </div>
                  <div className="col-md-6">
                  <div class="pricing-flx">
                  <div class="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div class="pricing-flx">
                    <p class="price-txt3">Custom Features Development</p>
                  </div>
                </div>
                  </div>
                  <div className="col-md-6">
                  <div class="pricing-flx">
                  <div class="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div class="pricing-flx">
                    <p class="price-txt3">Website Customisation</p>
                  </div>
                </div>
                  </div>
                  <div className="col-md-6">
                  <div class="pricing-flx">
                  <div class="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div class="pricing-flx">
                    <p class="price-txt3">Custom Branded Web Portals</p>
                  </div>
                </div>
                  </div>
                  <div className="col-md-6">
                  <div class="pricing-flx">
                  <div class="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div class="pricing-flx">
                    <p class="price-txt3">White Labeled Mobile Apps</p>
                  </div>
                </div>
                  </div>
                  <div className="col-md-6">
                  <div class="pricing-flx">
                  <div class="pricing-flx">
                    <i class="ri-check-line"></i>
                  </div>
                  <div class="pricing-flx">
                    <p class="price-txt3">Team Access Control</p>
                  </div>
                </div>
                  </div>
                </div>
                  <a href="#contact"><button class="stay-pricing1">Get Free Trial</button></a>

              </div>
              {/* <center><img src="img/stay1.png" className="img-fluid pricee-img2" alt="Resources" /></center> */}
            </div>

          </div>
        </div>
      </div>

    </>

  );
};

export default Component;
