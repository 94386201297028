// import Topbar from "../components/topbar/Component.js";
import Nav from "../components/nav/Component.js";
import Header from "../components/header/Component.js";
import Highlight from "../components/highlight/Component.js";
import Featureone from "../components/featureone/Component.js";
import Featuretwo from "../components/featuretwo/Component.js";
import Featurethree from "../components/featurethree/Component.js";
import Featurefour from "../components/featurefour/Component.js";
import Featurefive from "../components/featurefive/Component.js";
import Featuresix from "../components/featuresix/Component.js";
import Featureseven from "../components/featureseven/Component.js";
import Featureight from "../components/featureight/Component.js";
import HotelPricing from "../components/Hotelpricing/Component.js";
// import Hotelpricing from "../components/hotelpricing/Component.js";
// import Sectionfive from "../components/sectionfive/Component.js";
// import Sectionsix from "../components/sectionsix/Component.js";
// import Sectionseven from "../components/sectionseven/Component.js";
import Pricing from "../components/pricing/Component.js";
import Contact from "../components/contact/Component.js";
import Floatbtn from "../components/floatbtn/Component.js";
import Footer from "../components/footer/Component.js";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "./../css/style.css";

const Component = () => {
  return (
    <>
      <Nav />
      <Header />
      <Highlight />
      <Featureone />
      <Featuretwo />
      <Featurethree />
      <Featurefour />
      <Featurefive />
      <Featuresix />
      <Featureseven />
      <Featureight />
      <HotelPricing />
      {/* <Pricing/> */}
      <Contact />
      <Floatbtn />

      <Footer />
    </>
  );
};

export default Component;
