import React, { useState, useEffect, useContext } from "react";
import "../components/HotelCard/Component.css";
import HotelCard from "../components/HotelCard/Component";
import Navbar from "../components/Navbar/Component";
import { url, whoAmIHeaders } from "../../../lib/lib";
import { LoadingMutatingDots } from "../../hotels/components/ui/Loadings";
import { AuthUserContext } from "../../../lib/AuthUserContext";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const { whoAmI } = useContext(AuthUserContext);
  let pageHeaders = whoAmIHeaders(whoAmI);
  const navigate = useNavigate();

  const [myHotels, setMyHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    async function fetchHotels() {
      try {
        const response = await fetch(url + "/hotel/view", {
          method: "POST",
          headers: pageHeaders,
          credentials: "include",
          body: JSON.stringify({}),
        });

        if (!response.ok) {
          navigate("/login");
        }

        const data = await response.json();

        setClientName(data.Data[0].client_id.name);
        setIsLoading(false);
        setMyHotels(data.Data);
      } catch (error) {
        console.error("Error:", error.message);
        // setError("An error occurred while logging in. Please try again.");
        setIsLoading(false);
      }
    }

    fetchHotels();
  }, []);

  if (pageHeaders.type !== "client") {
    navigate(`/`);
  }

  if (isLoading) {
    return <LoadingMutatingDots />;
  }

  return (
    <>
      <Navbar name={clientName} />
      <div className="space-option">
        <div className="inner-padding1 dashboardfont">
          {/* hotels */}

          <div className="side-space-data">
            <div className="featured-flx">
              <div className="featured-flx1">
                <p className="main_heading1">Hotels</p>
              </div>
              {/* <div className='featured-flx1'>
          <button className='circle-btn-add icon'>
            <i className='ri-add-line'></i>
          </button>
        </div> */}
            </div>
            <div className="row">
              {myHotels.length < 0 ? (
                <p>No Hotels Found!</p>
              ) : (
                myHotels.map((data) => <HotelCard key={data._id} data={data} />)
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
