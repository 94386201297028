import AllWebsite from "../../components/branding/website/table/Component";
import { toast, ToastContainer } from "react-toastify";

export default function Component() {
  return (
    <>
      <ToastContainer />
      <AllWebsite name='Website' />
    </>
  );
}
