import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom"; // useNavigate for programmatic navigation
import "./component.css";
import { url, clientpoints } from "../../../lib/lib";
import { toast } from "react-toastify";
import "bootstrap-icons/font/bootstrap-icons.css";
import useHotelId  from "../../../lib/useHotelId.js";

const Component = () => {
  
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  let pageHeaders = {
    "Content-Type": "application/json",
    type: "client",
    authorization: "$^CLiENt$%^&I&^@4567",
    key: "jsjdj744%5GSHr45",
  };

  let { propertyName} = useParams();
  const hotelId = useHotelId(); 

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const [ratings, setRatings] = useState({
    overallExperience: "",
    roomQuality: "",
    staffService: "",
    facilitiesAmenities: "",
    locationConvenience: "",
    valueForMoney: "",
  });

  useEffect(() => {
    if (hotelId) {
      validatePropertyName();
    }
  }, [hotelId]); 

  const validatePropertyName = async () => {
    try {
      setPageLoading(true);
      const requestBody = {
        urlName: hotelId,
        property_url: propertyName,
      };

      const response = await fetch(url + clientpoints.property_check, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log("Feedback - ", responseData.Status);
      setData(responseData.Data);
      if (!responseData.Status) {
        navigate(`/`);
      }
      setPageLoading(false);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      navigate(`/`);
      setPageLoading(false);
    } finally {
      setPageLoading(false);
    }
  };

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobileNumber: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    dob: "",
    anniversary: "",
    feedback: "",
  });

  const handleCheckboxChange = (category, value) => {
    setRatings({ ...ratings, [category]: value });
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    // Make a copy of formData and errors state
    const newFormData = { ...formData };
    const newErrors = { ...errors };

    if (id === "dob") {
      newFormData.dob = value;
    }
    if (id === "anniversary") {
      newFormData.anniversary = value;
    }
    if (id === "feedback") {
      newFormData.feedback = value;
    } else if (id === "name") {
      if (value.trim() === "") {
        newErrors.name = "Name is required!";
      } else {
        newErrors.name = "";
      }
      newFormData.name = value;
    }

    // Handling validation for email field
    else if (id === "email") {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(value.trim())) {
        newErrors.email = "Please enter a valid email address!";
      } else {
        newErrors.email = "";
      }
      newFormData.email = value;
    }

    // Handling validation for mobile number field
    else if (id === "number") {
      if (/^\d*$/.test(value)) {
        newFormData.mobileNumber = value;

        const regex = /^\d{10}$/;
        if (value.length === 10 && regex.test(value)) {
          newErrors.mobileNumber = ""; // Valid mobile number
        } else if (value.length === 0) {
          newErrors.mobileNumber = ""; // Empty field is valid
        } else {
          newErrors.mobileNumber = "Mobile number must be exactly 10 digits.";
        }
      } else {
        newErrors.mobileNumber = "Only digits are allowed.";
      }
    }

    // Update both formData and errors state only if there are changes
    if (
      JSON.stringify(newFormData) !== JSON.stringify(formData) ||
      JSON.stringify(newErrors) !== JSON.stringify(errors)
    ) {
      setFormData(newFormData);
      setErrors(newErrors);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};

    const isNameValid = formData.name && formData.name.trim() !== "";
    const isEmailValid = formData.email && formData.email.trim() !== "";
    const isMobileNumberValid =
      formData.mobileNumber && formData.mobileNumber.trim() !== "";

    if (!isNameValid) errors.name = "Name is required!";
    if (!isEmailValid) errors.email = "Email is required!";
    if (!isMobileNumberValid)
      errors.mobileNumber = "Mobile number is required!";

    // If there are any errors, update the error state and stop the submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    // Clear any previous error messages
    setErrors({});

    const feedbackData = {
      ...ratings,
      ...formData,
      property_url: propertyName,
      urlName: hotelId,
      property_id: data.id,
      property_name: data.name,
    };

    try {
      setFormSubmitLoading(true);

      const response = await fetch(url + clientpoints.feedback_create, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(feedbackData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        toast.error("Something went wrong. Please try again.");
        navigate(`/`);
        throw new Error("Network response was not ok");
      }
      
      const responseData = await response.json();
      
      if (responseData.Status) {
        console.log("responseData - ", responseData);
        setFormSubmitted(true);
        toast.success(responseData.Message);
        setFormData({
          name: "",
          email: "",
          dob: "",
          anniversary: "",
          feedback: "",
          mobileNumber: "",
        });
        setRatings({
          overallExperience: "",
          roomQuality: "",
          staffService: "",
          facilitiesAmenities: "",
          locationConvenience: "",
          valueForMoney: "",
        });
      } else {
        toast.error(responseData.Message);
      }
      setFormSubmitLoading(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Something went wrong. Please try again.");
      setFormSubmitLoading(false);
    }
    finally{
      setFormSubmitLoading(false);
    }
  };

  /*
  if (formSubmitted) {
    setTimeout(() => {
    setFormSubmitted(false); // Reset the state after 3 seconds
    setFormData({
      name: "",
      email: "",
      dob: "",
      anniversary: "",
      feedback: "",
    });
    setRatings({
      overallExperience: "",
      roomQuality: "",
      staffService: "",
      facilitiesAmenities: "",
      locationConvenience: "",
      valueForMoney: "",
    });
    }, 6000);
  }
  */

  const questions = [
    {
      id: "overallExperience",
      question: "How would you rate your overall experience?",
    },
    { id: "roomQuality", question: "Quality of Rooms" },
    { id: "staffService", question: "Staff and Service" },
    { id: "facilitiesAmenities", question: "Facilities and Amenities" },
    { id: "locationConvenience", question: "Location and Convenience" },
    { id: "valueForMoney", question: "Value for Money" },
  ];

  const options = ["Poor", "Average", "Good", "Great"];

  const getButtonClass = (category, option) => {
    const isActive = ratings[category] === option;
    const baseClass = "btn btn-sm mx-1 my-1";
    if (!isActive) return `${baseClass} btn-outline-primary`;

    switch (option) {
      case "Poor":
        return `${baseClass} btn-danger`;
      case "Average":
        return `${baseClass} btn-warning`;
      case "Good":
        return `${baseClass} btn-primary`;
      case "Great":
        return `${baseClass} btn-success`;
      default:
        return baseClass;
    }
  };

  if (formSubmitted) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
        <div className="card shadow-lg text-center p-4">
          <div className="text-success fs-1">
            <i className="bi bi-check-circle"></i>
          </div>
          <h3 className="mt-3 fw-bold">
            {/* Thank You, <br /> Your Response Has Been Recorded */}
            Thank You, <br /> For Your Valuable Feedback. <br /> We Look Forward
            To Host You Again.
          </h3>
        </div>
      </div>
    );
  }

  return (
    <>
      {pageLoading ? (
        <>
          <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <div className="container py-5 card-container" id="feedback_form">
          <div className="row align-items-center">
            <div className="col-md-6" style={{ margin: "auto" }}>
              <div className="p-4 bg-white rounded shadow feedback-form">
                {data.logo && (
                  <img src={data.logo} alt="Logo" className="logo" />
                )}
                <h3 className="text-center form-title">Guest Feedback</h3>
                <p
                  className="text-secondary text-center"
                  style={{ display: "inline-table" }}
                >
                  What are your thoughts on the experience with
                  <strong className="highlight"> {data.name}</strong>?
                  {/* <strong className="highlight"> BLUO AMANI VAGATOR HOTEL</strong>? */}
                </p>
                {questions.map(({ id, question }) => (
                  <div key={id} className="mb-4">
                    <p className="category-title">{question}</p>
                    <div className="rating-container d-flex flex-wrap justify-content-start">
                      {options.map((option) => (
                        <button
                          key={option}
                          onClick={() => handleCheckboxChange(id, option)}
                          className={getButtonClass(id, option)}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="feedback" className="form-label">
                      Any other comments or suggestions?
                    </label>
                    <textarea
                      id="feedback"
                      className="form-control"
                      rows="3"
                      placeholder="Comments or Suggestions"
                      value={formData.feedback}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Your Name *
                    </label>
                    <input
                      type="text"
                      id="name"
                      className={`form-control ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Your Email *
                    </label>
                    <input
                      type="email"
                      id="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Enter your email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="number" className="form-label">
                      Your Mobile Number *
                    </label>
                    <input
                      type="text"
                      id="number"
                      className={`form-control ${
                        errors.mobileNumber ? "is-invalid" : ""
                      }`}
                      value={formData.mobileNumber}
                      onChange={handleInputChange}
                      placeholder="Enter your mobile number"
                    />
                    {errors.mobileNumber && (
                      <div className="invalid-feedback">
                        {errors.mobileNumber}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="dob" className="form-label">
                      Birthday:
                    </label>
                    <input
                      type="text"
                      id="dob"
                      className="form-control"
                      placeholder="Enter your Birthday"
                      value={formData.dob}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="anniversary" className="form-label">
                      Anniversary:
                    </label>
                    <input
                      type="text"
                      id="anniversary"
                      className="form-control"
                      placeholder="Enter your Anniversary"
                      value={formData.anniversary}
                      onChange={handleInputChange}
                    />
                  </div>

                  {/* <button type="submit" className="btn btn-primary w-100">Submit</button> */}
                  <button type="submit" className="btn btn-primary w-100" disabled={formSubmitLoading} >
                    {formSubmitLoading ? ( <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : ("Submit")}
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Component;
