import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Hotels from "./components/Hotel/Component";
// import Dashboard from "./components/Dashboard/Component";
import Dashboard from "./screens/Dashboard.js";
import PrivateRoutes from "../../utils/PrivateRoute.js";
import PublicRoutes from "../../utils/PublicRoute.js";
import Login from "../../website/components/login/Component.js";
import { ToastContainer } from "react-toastify";
// import HotelLayout from "./components/ui/HotelLayout.js";
import HotelLayout from "./components/ui/hotelLayout/Component.js";
import "./main.css";
import TestComponent from "./components/TestComponent.js";
// import Locations from "./screens/Resources/Locations.js";
// import Floors from "./screens/Resources/Floors.js";
// import AssetsMapping from "./screens/Resources/AssetsMapping.js";
import "react-toastify/dist/ReactToastify.css";

import Subscription from "./screens/Settings/Subscription.js";
import Profile from "./screens/Settings/Profile.js";
import ActivityLog from "./screens/Settings/ActivityLog.js";

import CommunityPost from "./screens/community/Post.js";
import CommunityEvent from "./screens/community/Event.js";
import CommunityOffer from "./screens/community/Offer.js";
import CommunityBulkEmail from "./screens/community/BulkEmail.js";

import Booking from "./screens/Booking/Booking.js";
import Feedback from "./screens/Feedback/Feedback.js";

import Website from "./screens/branding/Website.js";

import AllClients from "./screens/client/AllClients.js";

import Visitor from "./screens/visitor/Visitor.js";
import Coupon from "./screens/offers/Coupons.js";

import Enquirie from "./screens/enquirie/Enquirie.js";

import Expenses from "./screens/expenses/Expenses.js";

import TeamRoles from "./screens/team/Roles.js";
import TeamStaff from "./screens/team/Staff.js";
import TeamAttendance from "./screens/team/Attendance.js";

import ClientSupport from "./screens/Support/ClientSupport.js";
import StayStationSupport from "./screens/Support/StayStationSupport.js";
import PropertyType from "./screens/Masters/PropertyType.js";
import RoomType from "./screens/Masters/RoomType.js";
import PricingType from "./screens/Masters/PricingType.js";
import PropertyAmenity from "./screens/Masters/PropertyAmenity.js";
import ApartmentAmenity from "./screens/Masters/ApartmentAmenity.js";
import Faqs from "./screens/Masters/Faqs.js";
import Policy from "./screens/Masters/Policy.js";
import Areas from "./screens/Resources/Areas.js";
import Properties from "./screens/Resources/Properties.js";
import Rooms from "./screens/Resources/Rooms.js";
import Reviews from "./screens/Resources/Reviews.js";
import Inventory from "./screens/Resources/Inventory.js";
import NotFound from '../../website/screens/NotFound.js'
import { Helmet } from "react-helmet";

const HotelsRoutes = () => {
  return (
    <>
      <HotelLayout>

        <Helmet>
          <title>StayStation - Admin Pannel</title>
        </Helmet>
        
        <Routes>

          <Route path="dashboard" element={<Dashboard />} />
          
          <Route path="masters">
            <Route path="property-types" element={<PropertyType />}></Route>
            <Route path="room-types" element={<RoomType />}></Route>
            <Route path="pricing-types" element={<PricingType />}></Route>
            <Route path="property-amenities" element={<PropertyAmenity />}></Route>
            <Route path="room-amenities" element={<ApartmentAmenity />}></Route>
            <Route path="faqs" element={<Faqs />}></Route>
            <Route path="policies" element={<Policy />}></Route>
          </Route>

          <Route path="resources">
            {/* 
            <Route path='locations' element={<Locations />}></Route>
            <Route path='floors' element={<Floors />}></Route>
            <Route path='assets-mapping' element={<AssetsMapping />}></Route> 
            */}
            <Route path="areas" element={<Areas />}></Route>
            <Route path="properties" element={<Properties />}></Route>
            <Route path="rooms" element={<Rooms />}></Route>
            <Route path="inventory" element={<Inventory />}></Route>
          </Route>
          
          <Route path="front-office">
            <Route path="reservations" element={<Booking />}></Route>
            <Route path="quotations" element={<Enquirie />}></Route>
            <Route path="guests" element={<AllClients />}></Route>
            <Route path="visitors" element={<Visitor />}></Route>
            <Route path="feedbacks" element={<Feedback />}></Route>
            <Route path="reviews" element={<Reviews />}></Route>
          </Route>
          
          <Route path="branding">
            <Route path="website" element={<Website />}></Route>
          </Route>

          <Route path="offers">
            <Route path="coupons" element={<Coupon />}></Route>
          </Route>

          <Route path="staff">
            <Route path="roles" element={<TeamRoles />}></Route>
            <Route path="users" element={<TeamStaff />}></Route>
            <Route path="attendance" element={<TeamAttendance />}></Route>
          </Route>

          <Route path="occupancy">
            <Route path="reports" element={<TestComponent />}></Route>
            <Route path="analytics" element={<TestComponent />}></Route>
          </Route>

          <Route path="support">
            <Route path="guest-support" element={<ClientSupport />}></Route>
            <Route path="staystation-support" element={<StayStationSupport />}></Route>
          </Route>

          <Route path="settings">
            <Route path="profile" element={<Profile />}></Route>
            <Route path="subscription" element={<Subscription />}></Route>
            <Route path="activity-logs" element={<TestComponent />}></Route>
          </Route>

          {/* 
            <Route path="community">
              <Route path="posts" element={<CommunityPost />}></Route>
              <Route path="events" element={<CommunityEvent />}></Route>
              <Route path="offers" element={<CommunityOffer />}></Route>
              <Route path="bulk-email" element={<CommunityBulkEmail />}></Route>
            </Route>
            <Route path="invoices">
              <Route path="all" element={<TestComponent />}></Route>
              <Route path="pending" element={<TestComponent />}></Route>
              <Route path="paid" element={<TestComponent />}></Route>
            </Route>
            <Route path="expenses">
              <Route path="all-expenses" element={<Expenses />}></Route>
            </Route>
          */}

        </Routes>
      </HotelLayout>
    </>
  );
};

export default HotelsRoutes;
