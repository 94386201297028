import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ComingSoonScreen from "./screens/ComingSoonScreen.js";

const ComingSoon = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ComingSoonScreen />} />
      </Routes>
    </>
  );
};

export default ComingSoon;
